/*----------------------
      GLOBAL COMPS 
------------------------*/

//loader
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($color: #fff, $alpha: .6);

  &.full {
    background-color: #fff;
  }
}

.spinner {
  width: 50px;
  height: 24px;
  background:
    radial-gradient(circle closest-side, #97604C 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #97604C 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #97604C 90%, #0000) 100% 50%;
  background-size: calc(100%/3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}

@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}


.CookieConsent {
  a {
    margin-left: 5px;
    color: #fff;
  }
}

//inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Hide the default checkbox */
.sms-checkbox {
  display: none;
}

/* Style the custom checkbox */
.sms-checkbox+label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

/* Checkbox icon */
.sms-checkbox+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  background-color: #fff;
}

/* Checked checkbox icon */
.sms-checkbox:checked+label:before {
  background-color: #000;
  border-color: #000;
}

/* Checkbox checkmark */
.sms-checkbox:checked+label:after {
  content: "\2713";
  position: absolute;
  top: -1px;
  left: 5px;
  color: white;
}

/* Hover state */
.sms-checkbox+label:hover:before {
  border-color: #000;
}

//COMMENTS

.slider-reviews {

  .carousel-indicators {
    bottom: -60px;
  }

  .carousel-item {
    padding: 0 1rem;

    .box-comments {
      background-color: $c-green;
      border-radius: 15px;
      padding: 1rem;
      width: 100%;

      .comment {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;

        .pic {
          img {
            border: 4px solid $c-rosemed;
            border-radius: 50%;
            width: 110px;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          color: $c-roselight;

          .name {
            font-family: 'Minion Pro', serif;
            font-weight: bold;
            font-size: 30px;
            line-height: 1;
            margin-bottom: -8px;
          }

          .opi-tx {
            font-size: 12px;
          }

        }

      }
    }

  }
}



//newsletter
.newsletter-module {
  background: url('./images/hero-news-table.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 70vh;

  .box-form {
    margin: 2rem auto;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    .form-control {
      border-radius: 0;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 2px solid $c-rosemed;
      outline: none !important;
      box-shadow: none !important;
    }

    .invalid-feedback {
      display: inline-flex;
    }

    .text-center {
      margin-top: 2rem;
    }

    .thx {
      color: $c-rosedark;
      font-size: 16px;
      text-align: center;
      margin: 3rem auto;
    }

  }
}

.home-box {
  background-color: $c-roselight;
  padding: 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 100%;

  .photo {}

  .boton {
    margin-top: -23px;
    margin-bottom: 2rem;
  }

  .text {
    font-size: 28px;
    font-family: 'Minion Pro', serif;
    font-weight: bold;
    color: $c-greendark;
    line-height: 1.2;
    min-height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.roci-check {
  width: 14px;
  height: 14px;
  background-color: #DACFC9;
  border-color: #DACFC9;
  ;
  outline: none !important;
  box-shadow: none !important;
}

.roci-check:checked {
  background-color: $c-rosedark;
  border-color: $c-rosedark;
}