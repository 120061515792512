/*----------------------
      GLOBAL APP SCSS
------------------------*/

//----------
//vars
$c-roselight : #DACFC9;
$c-rosemed: #D7AA9E;
$c-rosesection: #D2A393;
$c-rosedark: #97604C;
$c-green: #808078;
$c-greendark: #52543C;
$c-orange: #BA8346;

$breakpoint-desktop: 991px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 500px;

//----------

@import "~bootstrap/scss/bootstrap.scss";
@import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

@import "font";
@import "components";
@import "main";
@import "media";