/*----------------------
         MAIN 
------------------------*/

html {
   scroll-behavior: smooth;
}

html,
body {
   -webkit-font-smoothing: antialiased;
   font-family: 'Poppins', sans-serif;
   font-weight: 500;
   color: #000;
   background-color: #DACFC9;
   font-size: 14px;
}

.fmin {
   font-family: 'Minion Pro', serif;
   font-weight: bold;
}

.fpop {
   font-family: 'Poppins', sans-serif;
   font-weight: 500;
}

//-----------------

.main-layout {
   overflow-x: hidden;
}

.main-header {
   background-color: $c-rosemed;
   display: flex;
   align-items: center;

   .logo {
      width: 230px;
   }

   .navbar {

      .navbar-collapse {
         justify-content: flex-end;
      }

      .nav-item {
         padding: 0 2.5rem;

         .nav-link {
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            position: relative;

            &::before {
               content: "";
               position: absolute;
               display: block;
               width: 100%;
               height: 2px;
               bottom: 0;
               left: 0;
               background-color: #fff;
               transform: scaleX(0);
               transition: transform 0.3s ease;
            }

            &:hover::before {
               transform: scaleX(1);
            }

            &.active {
               &::before {
                  transform: scaleX(1);
               }
            }
         }
      }
   }

   .navbar-toggler {
      outline: none !important;
      box-shadow: none !important;
   }
}

.main-container {

   .spacer-upper {
      height: 5rem;
      width: 100%;
      display: block;
   }

   .spacer-bottom {
      height: 5rem;
      width: 100%;
      display: block;
   }

   h1,
   h2,
   h3 {
      font-family: 'Minion Pro', serif;
      font-weight: bold;
      color: $c-rosedark;
      font-size: 3rem;
   }

   .intro-section {
      text-align: center;
      margin-bottom: 4rem;

      h1 {
         margin-bottom: 2rem;
      }

      p {
         margin-bottom: 2rem;
      }
   }

   p {
      white-space: pre-line;
      font-size: 15px;
   }
}

.main-footer {
   background-color: $c-rosemed;
   display: flex;
   align-items: center;
   padding: 2rem;

   .footer-layer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3rem;

      .footer-sign {
         margin-right: .5rem;

         img {
            width: 150px;
         }
      }

      .redes {
         display: flex;
         flex-direction: column;
         gap: 1rem;

         img {
            width: 40px;
            opacity: 0.5;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
               opacity: 1;
            }
         }
      }

      .info {
         font-size: 12px;

         p {
            &:last-child {
               margin-bottom: 0;
            }
         }

         .bodas-net {
            margin: 1rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
         }

      }
   }
}

.bg-green {
   background-color: $c-green;
}

.tx-green {
   color: $c-green !important;
}

.tx-orange {
   color: $c-orange !important;
}

.bg-rosesection {
   background-color: $c-rosesection;
}

.btn-info {
   color: $c-roselight;
   background-color: $c-rosedark;
   border: none;
   font-family: 'Minion Pro', serif;
   font-weight: bold;
   border-radius: 10px;
   font-size: 18px;
   padding: 8px 15px 4px;

   &:hover,
   &:active {
      background-color: $c-rosemed !important;
      color: $c-rosedark !important;
   }
}

.btn-default {
   background-color: $c-rosedark;
   color: $c-roselight;
   border: none;
   font-family: 'Minion Pro', serif;
   font-weight: bold;
   font-size: 20px;
   padding: 6px 30px 3px;

   &:hover,
   &:active {
      background-color: $c-rosemed !important;
      color: $c-rosedark !important;
   }
}

.btn-transparent {
   background-color: transparent;
   border: 2px solid #fff;
   color: $c-roselight;
   font-family: 'Minion Pro', serif;
   font-weight: bold;
   font-size: 20px;
   padding: 8px 30px 4px;

   &:hover,
   &:active {
      background-color: transparent !important;
      border-color: $c-roselight;
      color: #fff !important;
   }
}

.btn-warning {
   background-color: #BA8346;
   color: $c-roselight;
   border: none;
   font-family: 'Minion Pro', serif;
   font-weight: bold;
   border-radius: 10px;
   font-size: 24px;
   padding: 6px 30px 3px;

   &:hover,
   &:active {
      background-color: $c-rosemed !important;
      color: $c-rosedark !important;
   }
}

.btn-rosebig {
   background-color: $c-rosemed;
   color: $c-rosedark;
   border: none;
   font-family: 'Minion Pro', serif;
   font-weight: bold;
   border-radius: 10px;
   font-size: 34px;
   padding: 6px 40px 3px;

   &:hover,
   &:active {
      background-color: $c-roselight !important;
      color: $c-rosedark !important;
   }
}

//-----------------


//-----------------

.newsletter-module {}


//-----------------

.footer {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   background-color: #000;
   color: #fff;
   padding: 1rem;
}


//-----------------

.home {
   .hero {
      background: url('./images/main-hero.jpg') no-repeat center center;
      background-size: cover;
      display: flex;
      align-items: center;
      min-height: 90vh;

      h1 {
         color: $c-roselight;
         font-size: 3.5rem;
      }

      h2 {
         color: $c-roselight;
         margin-bottom: 3rem;
         font-size: 2.5rem;
      }
   }


   .doing {
      h1 {
         color: #fff;
      }
   }

   .life {
      background: url('./images/hero-contact.jpg') no-repeat center center;
      background-size: cover;
      display: flex;
      align-items: center;
      min-height: 60vh;
      margin-top: -130px;
      padding-top: 130px;

      h1 {
         font-size: 3.5rem;
         color: $c-roselight;
      }
   }

   .hello {
      padding: 5rem 1rem;

      .soy-hello {
         .signature {
            display: flex;
            flex-direction: row;

            img {
               width: 100px;
               margin-right: 1rem;
            }

            .title {
               line-height: 1.2;
               color: $c-rosedark;
               font-size: 40px;
               font-family: 'Minion Pro', serif;
               font-weight: bold;
            }
         }

         a {
            font-weight: bold;
            color: $c-rosedark;
         }

         .text {
            margin-top: 2rem;
            white-space: pre-line;
         }


      }
   }

   .trends {}
}

//-----------------

.newsletter-module {}


//-----------------

.momentos-page {
   margin-top: 5rem;

   .grid-momentos {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 4rem;
      row-gap: 5rem;
      margin-bottom: 3rem;

      .momento {
         width: 100%;

         img {
            width: 100%;
            height: auto;
            object-fit: contain;
            transition: all 0.3s ease;

            &:hover {
               transform: scale(1.01);
               box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            }

         }
      }
   }
}

//-----------------

.blog-page {
   margin-top: 5rem;

   .grid-blog {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 4rem;
      row-gap: 5rem;
      margin-bottom: 3rem;
      align-items: stretch;

      .blogpost {
         width: 100%;
         text-align: center;

         .entry {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            padding-bottom: 2rem;

            img {
               width: 100%;
               height: auto;
               object-fit: contain;
               margin-bottom: 1rem;
            }

            .tit {
               padding: 1rem;
               text-align: center;
               font-size: 16px;
               min-height: 76px;
            }
         }

         .btn {
            margin-top: -20px;
         }

      }
   }
}

//-----------------

.contact-page {
   margin-top: 5rem;

   .intro-section {
      h1 {
         &:last-child {
            margin-top: 4rem;
         }
      }
   }

   .contact-layer {
      padding: 5rem 1rem;

      .big-photo {
         padding: 2rem;
         background-color: $c-roselight;
         box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
         transform: rotate(.7deg);
         margin-right: 4rem;
      }

      .contact-form {
         .form-control {
            background-color: transparent;
            border-radius: 0;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 1px solid $c-roselight;
            outline: none !important;
            box-shadow: none !important;
            color: $c-roselight;
         }

         .thx {
            text-align: center;
            font-size: 20px;
            width: 80%;
            margin: 2rem auto;
         }

         textarea {
            resize: none;
         }

         .invalid-feedback {
            display: inline-flex;
         }

         .col-sm-6 {
            padding: 0 2rem;
         }
      }
   }

   .contact-footer {
      padding-top: 3rem;

      .info {
         h1 {
            margin-bottom: 2rem;
         }

         .contact-info {
            display: flex;
            align-items: center;
            margin-bottom: .7rem;
            margin-left: 2rem;

            p {
               margin-bottom: 0;
            }

            a {
               color: #000;
               text-decoration: none;

               &:hover {
                  text-decoration: underline;
               }
            }

            svg {
               margin-right: .7rem;
               color: $c-rosedark;
            }
         }
      }

      .legal {
         p {
            margin-bottom: 0;
         }
      }
   }
}

//-----------------

.services-page {
   margin-top: 5rem;

   .services-hero {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 55vh;

      h1 {
         margin-bottom: 0;
         color: $c-roselight;
      }
   }

   .info {
      width: 75%;
      margin: 0 auto;

      h1 {
         margin-bottom: 2rem;
      }

      .btn {
         margin-top: 3rem;
      }
   }
}

//-----------------

.about-page {
   margin-top: 5rem;

   .big-photo {
      padding: 2rem;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      transform: rotate(.7deg);
   }

   .info {
      width: 75%;
      margin: 0 auto;

      h1 {
         margin-bottom: 2rem;
      }

      .btn {
         margin-top: 3rem;
      }

      a {
         font-weight: bold;
         color: $c-rosedark;
      }
   }
}


//-----------------

//-----------------

//-----------------