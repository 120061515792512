/*----------------------
         FONTS 
------------------------*/

@font-face {
    font-family: 'Minion Pro';
    src: url('fonts/MinionPro-Bold.woff2') format('woff2'),
        url('fonts/MinionPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/Poppins-Medium.woff2') format('woff2'),
        url('fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

