/*----------------------
         MEDIAQs 
------------------------*/

@media (max-width: 910px) {}

@media (max-width: 765px) {
   .slider-reviews {

      .carousel-item {

         .box-comments {
            padding: 1.5rem;

            .comment {
               flex-direction: column;
               margin-top: 2rem;

               .pic,
               .name,
               .starts {
                  text-align: center;
               }
            }

         }
      }
   }
}

@media (max-width: 600px) {

   .home .life h1 {
      font-size: 3rem;
   }

   .btn-rosebig {
      font-size: 28px;
   }

   .newsletter-module {
      padding: 4rem 1rem;
   }

   .main-footer {
      .footer-layer {
         flex-direction: column;
         gap: 1.5rem;

         .footer-sign {
            img {
               width: 120px;
            }
         }

         .redes {
            flex-direction: row;
         }
      }

   }


   .momentos-page {
      .grid-momentos {
         row-gap: 1rem;
      }
   }

   .about-page .info,
   .services-page .info {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 90%;
   }

   .contact-page .contact-layer .big-photo {
      margin-right: 0;
      margin-bottom: 4rem;
   }

   .contact-form {
      .row {
         margin-bottom: 0 !important;
      }

      .col-sm-6,
      #numinvitados,
      #lugar,
      #presu {
         margin-bottom: 2rem !important;
      }
   }

   .contact-footer {
      .legal {
         padding: 2rem;

         p {
            font-size: 12px;
         }
      }
   }




}